import request from '@/utils/request'

export function getDict (params) {
  return request({
    url: '/api/common/getDict',
    method: 'get',
    params
  })
}
export function getDictNotToken (params) {
  return request({
    url: '/api/common/getDictNotToken',
    method: 'get',
    params
  })
}
export function trans (params) {
  return request({
    headers: {
      isToken: false
    },
    url: '/api/common/trans?str=' + params,
    method: 'get'
  })
}
