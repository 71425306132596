import request from '@/utils/request'

export function addProduct (data) {
  return request({
    url: '/api/supplierProduct/add',
    method: 'post',
    data
  })
}
export function getProductList (params) {
  return request({
    url: '/api/supplierProduct/list',
    method: 'get',
    params
  })
}
export function getProductListCenter (params) {
  return request({
    url: '/api/supplier/getProductList',
    method: 'get',
    params
  })
}
export function getProductTypeList () {
  return request({
    url: '/api/client/getProductTypeList',
    method: 'get'

  })
}
export function getProductDetail (params) {
  return request({
    url: '/api/client/getProductDetail',
    method: 'get',
    params
  })
}
export function getProductDetailNotToken (params) {
  return request({
    url: '/api/client/getProductDetailNotToken',
    method: 'get',
    params
  })
}
export function editProduct (data) {
  return request({
    url: '/api/supplierProduct/edit',
    method: 'post',
    data
  })
}

export function delProduct (data) {
  return request({
    url: '/api/supplierProduct/delete',
    method: 'post',
    data
  })
}
