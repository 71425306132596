<template>
    <div class="container_f">
        <div class="container_foot">
            <div class="container_foot_up">
                <div class="container_foot_up_left">
                    <div class="container_foot_up_left_one">
                        <img src="../assets/images/home/foot/logo2.png" alt="">
                        <div class="container_foot_up_left_one_name">
                            ECO Carbon Tracer
                        </div>
                    </div>
                    <div class="container_foot_up_left_two">
                        <div class="container_foot_up_left_two_left">北京 Beijing</div>
                        <div class="container_foot_up_left_two_right">上海 Shanghai</div>
                    </div>
                    <div class="container_foot_up_left_two">
                        <div class="container_foot_up_left_two_left">深圳 Shenzhen</div>
                        <div class="container_foot_up_left_two_right">阿布扎比 AbuseDhabi</div>
                    </div>
                    <div class="container_foot_up_left_two">
                        <div class="container_foot_up_left_two_left">纽约 New York</div>
                        <div class="container_foot_up_left_two_right">都柏林 Dublin</div>
                    </div>
                    <div class="container_foot_up_left_three">
                        <img src="../assets/images/home/foot/email.png" alt="">
                        <div class="container_foot_up_left_three_address">
                            info@ecocarbontrace.com
                        </div>
                    </div>
                </div>
                <div class="container_foot_up_right">
                    <div class="container_foot_up_right_pic">
                        <img src="../assets/images/home/foot/1.png" alt="">
                        <img src="../assets/images/home/foot/2.png" alt="">
                        <img src="../assets/images/home/foot/3.png" alt="">
                        <img src="../assets/images/home/foot/9.png" alt="">
                        <img src="../assets/images/home/foot/5.png" alt="">
                        <img src="../assets/images/home/foot/6.png" alt="">
                        <img src="../assets/images/home/foot/7.png" alt="">
                        <img src="../assets/images/home/foot/8.png" alt="">
                        <img src="../assets/images/home/foot/4.png" alt="">
                        <img src="../assets/images/home/foot/10.png" alt="">
                        <img src="../assets/images/home/foot/11.png" alt="">
                        <img src="../assets/images/home/foot/12.png" alt="">
                    </div>
                    <div class="container_foot_up_right_address">
                        <img src="../assets/images/home/foot/15.png" alt="">
                        <img src="../assets/images/home/foot/14.png" alt="">
                        <img src="../assets/images/home/foot/16.png" alt="">
                        <img src="../assets/images/home/foot/13.png" alt="">
                    </div>
                </div>
            </div>
            <div class="container_foot_bottom">
                © 2023 ECO. All rights reserved.
            </div>
        </div>
    </div>
</template>

<script setup name="Foot">
</script>

<style lang="scss" scoped>
.container_f {
    width: 100%;
    background-color: rgba(250, 250, 251, 1);

    .container_foot {
        width: 1600px;
        margin: 0 auto;
        padding-top: 50px;
        border-radius: 4px;
        background-color: rgba(250, 250, 251, 1);
        display: flex;
        flex-direction: column;
        align-items: start;

        &_up {
            display: flex;

            &_left {
                flex: 1;
                display: flex;
                flex-direction: column;

                &_one {
                    margin-bottom: 50px;
                    display: flex;

                    img {
                        width: 80px;
                        height: 80px;
                    }

                    &_name {
                        font-weight: bold;
                        margin-left: 20px;
                        line-height: 80px;
                        color: rgba(16, 16, 16, 1);
                        font-size: 24px;
                        text-align: left;
                        font-family: HarmonyOS_Sans_SC-black;
                    }
                }

                &_two {
                    margin-bottom: 30px;
                    display: flex;
                    height: 14px;
                    color: rgba(61, 61, 78, 1);
                    font-size: 14px;
                    text-align: left;
                    font-family: Helvetica-regular;

                    &_left {
                        flex: 1;
                    }

                    &_right {
                        flex: 2;
                    }
                }

                &_three {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    &_address {
                        margin-left: 5px;
                        height: 16px;
                        color: rgba(24, 24, 24, 1);
                        font-size: 14px;
                        text-align: left;
                        font-family: SourceHanSansSC-regular;
                    }
                }
            }

            &_right {
                flex: 1;
                display: flex;
                flex-direction: column;

                &_pic {
                    font-size: 0;
                    display: flex;
                    flex-wrap: wrap;

                    img {
                        width: 140px;
                        height: 60px;
                        object-fit: cover;
                        border-radius: 2px;
                        overflow: hidden;
                        margin-left: 20px;
                        margin-bottom: 10px;
                    }
                }

                &_address {
                    font-size: 0;
                    display: flex;
                    flex-wrap: wrap;

                    img {
                        width: 290px;
                        height: 44px;
                        object-fit: cover;
                        border-radius: 2px;
                        overflow: hidden;
                        margin-left: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        &_bottom {
            width: 1200px;
            margin: 0 auto;
            margin-top: 60px;
            padding: 30px 0;
            color: rgba(150, 150, 150, 1);
            font-size: 14px;
            text-align: center;
            border-top: 1px solid rgba(240, 240, 240, 1);
        }
    }
}
</style>
